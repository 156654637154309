<template>
  <!-- avatar -->
  <v-avatar v-if="avatar">
    <img :src="error ? noImageUrl : src" @error="error = true" v-if="src" />
    <img :src="noImageUrl" v-else />
  </v-avatar>
  <!-- avatar 以外 -->
  <v-img
    v-else-if="src"
    :src="error ? noImageUrl : src"
    @error="error = true"
    contain
  />
  <v-img :src="noImageUrl" v-else />
</template>

<script>
// 画像読込失敗時(404)には noimage 画像表示
export default {
  name: "ImgWrapper",
  components: {},
  props: {
    src: {
      type: String,
    },
    avatar: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    error: false,
    noImageUrl: require("@/assets/noimage.png"),
  }),
};
</script>
